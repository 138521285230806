<script setup lang="ts">
import {defaults} from "~/types/FormFieldProps"
import formFieldExpose from "~/components/atoms/form/form-field-expose"

interface Props {
  // This is common with other fields
  name?: string,
  label?: string,
  modelValue?: string,
  placeholder?: string,
  autofocus?: boolean,
  required?: boolean,
  error?: string,

  labelClass?: any,
  inputClass?: any,

  // This is specific (nothing yet)
}

const props = withDefaults(defineProps<Props>(), {...defaults})

const {inputClass: baseInputClass} = useFormItem()

const input = ref<HTMLInputElement | null>(null)

defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

defineExpose(formFieldExpose(input, props))
</script>

<template>
  <label class="block">
    <AtomsFormLabel :text="label" :required="required" />
    <textarea ref="input"
              :autofocus="autofocus"
              :class="[baseInputClass, inputClass, error ? '!border-red' : '']"
              :name="name"
              :placeholder="placeholder"
              :required="required"
              :value="modelValue"
              @input="$emit('update:modelValue', $event.target.value)"></textarea>
    <AtomsFormFieldError :text="error" />
    <slot name="after-input"></slot>
  </label>
</template>